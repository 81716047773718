import React from 'react';
import { Helmet } from 'react-helmet';

// https://www.gatsbyjs.org/docs/using-client-side-only-packages/
class WistiaVideo extends React.Component {

  constructor(props) {
    super(props);
    this.imgRef = React.createRef();
  }

  // componentWillMount() {
  //   const script1 = window.document.createElement('script');
  //   const script2 = window.document.createElement('script');
  //   script1.src = 'https://fast.wistia.com/embed/medias/skg5gbc4nr.jsonp';
  //   script1.async = true;
  //   script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
  //   script2.async = true;
  //   window.document.body.appendChild(script1);
  //   window.document.body.appendChild(script2);
  // }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <script src="https://fast.wistia.com/embed/medias/skg5gbc4nr.jsonp" async={true} />
          <script src="https://fast.wistia.com/assets/external/E-v1.js" async={true} />
        </Helmet>
        <div style={{ width: '712px', height: '400px' }}>
          <div
            className="wistia_responsive_padding"
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                width: '100%'
              }}
            >
              <div
                className="wistia_embed wistia_async_skg5gbc4nr videoFoam=true"
                style={{ height: '100%', position: 'relative', width: '100%' }}
              >
                <div
                  className="wistia_swatch"
                  style={{
                    height: '100%',
                    left: 0,
                    opacity: 0,
                    overflow: 'hidden',
                    position: 'absolute',
                    top: 0,
                    transition: 'opacity 200ms',
                    width: '100%'
                  }}
                >
                  <img ref={this.imgRef}
                    src="https://fast.wistia.com/embed/medias/skg5gbc4nr/swatch"
                    style={{
                      filter: 'blur(5px)',
                      height: '100%',
                      objectFit: 'contain',
                      width: '100%'
                    }}
                    alt=""
                    aria-hidden={true}
                    // onLoad={() => { this.imgRef.current.parentNode.style.opacity = 1; }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default WistiaVideo;